import styled from "@emotion/styled";
import StobhillImage from "../../assets/HospitalImage.png";
import GRIImage from "../../assets/GRI.jpg";
import MonklandsImage from "../../assets/MONKLANDS.jpg";

// Hooks
import React, { useState, useEffect, Suspense } from "react";
import { useLocation } from "react-router-dom";

const hospitalImage = {
  STOBHILL: <img className="hospital-image" src={StobhillImage} alt="Stobhill Hospital" />,
  GRI: <img className="hospital-image" src={GRIImage} alt="Glasgow Royal Infirmary" />,
  MONKLANDS: <img className="hospital-image" src={MonklandsImage} alt="Monklands Hospital" />,
};

const proceedureInfo = {
  BLOOD_TEST: React.lazy(() => import("../../comp/appointmentTypes/BLOOD_TEST")),
  ULTRASOUND_SCAN: React.lazy(() => import("../../comp/appointmentTypes/ULTRASOUND_SCAN")),
  ECHOCARDIOGRAM: React.lazy(() => import("../../comp/appointmentTypes/ECHOCARDIOGRAM")),
};
// import { BloodTest } from '../../comp/appointmentTypes/Tests';

// import { UltrasoundScan } from '../../comp/appointmentTypes/Scans';

const AppointmentBaseContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 4vmin auto;
  height: auto;
  position: relative;
  text-align: justify;
  font-size: 8px;
  @media (max-width: 1000px) {
    font-size: 6px;
  }
  @media (max-width: 700px) {
    font-size: 5px;
  }

  & h1 {
    font-size: 5em;
    text-align: left;
  }
  & h2 {
    font-size: 4em;
    text-align: left;
  }
  & h3 {
    font-size: 3em;
    text-align: left;
  }
  & h4 {
    font-size: 2.5em;
    text-align: left;
  }
  & p {
    font-size: 2em;
  }
`;

const HospitalInfoCotainer = styled.div`
  flex: 2;
  height: auto;
  width: 100%;
  position: relative;
  display: flex;
  border-radius: 10px;
  flex-wrap: wrap;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
  @media (max-width: 700px) {
  }

  & img,
  .hospital-image {
    height: 100%;
    width: 100%;
    max-height: 30vw;
    max-width: 30vw;
    object-fit: cover;
    object-position: center;
    flex: 1;
    border-radius: 10px 0 0 10px;
    @media (max-width: 1000px) {
      display: none;
    }
    @media (max-width: 700px) {
    }
  }
  & div {
    flex: 1;
    height: auto;
  }
`;

const HospitalInfo = styled.div`
  flex: 1;
  height: auto;
  background: #ffffff;
  padding: 2vmin;
  border-radius: 0 10px 10px 0;
  margin-right: 2vmin;
`;

const Map = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  position: relative;
  background: #ffffff;
  border-radius: 10px;
  & > * {
    width: 100%;
    height: 100%;
    border: 0;
  }
`;
const Row1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2vmin;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
  @media (max-width: 700px) {
  }
`;
const Column1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  margin-right: 1vmin;
  @media (max-width: 1000px) {
    margin-right: 0;
    margin-top: 20px;
  }
  @media (max-width: 700px) {
  }
`;
const Column2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  margin-left: 1vmin;
  @media (max-width: 1000px) {
    margin-left: 0;
    margin-top: 20px;
  }
  @media (max-width: 700px) {
  }
`;

const AppointmentInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  background: #ffffff;
  border-radius: 10px;
  padding: 2vmin;

  & h4 {
    margin: 0;
  }
`;

const AppointmentDirections = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
  background: #ffffff;
  border-radius: 10px;
  padding: 2vmin;
  margin-top: 2vmin;
`;

const getAppointment = (appointmentType) => {
  const Component = proceedureInfo[appointmentType];
  return <Component />;
};

const getHospitalImageURL = (hospital) => {
  const Component = hospitalImage[hospital];
  return Component;
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const defaultData = {
  hospital: {
    name: "XXXXXXXXXX",
    description: "XXXXXXXXXXXXX",
    address: "X XXXXX XXX",
    postcode: "XXX XXX",
    number: "XXXXXXXXXXX",
    website: "XXXXXXXXXXX",
    parking: false,
    transport: "XXXXXXX",
    mapHTML:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2237.285110543636!2d-4.220370683944117!3d55.892414488682846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x488843890455ebf9%3A0x9f08895e757b45c5!2sNew%20Stobhill%20Hospital!5e0!3m2!1sen!2suk!4v1647437668722!5m2!1sen!2suk",
  },
  appointment: {
    location: "XXX",
    appointmentType: "XXXXXX",
    date: "XXXXXXXXXXXXX",
    time: "XXXXXXXXXXXXX",
    ward: "XXX",
  },
};

export default function AppointmentBase() {
  const [hospitalData, setHospitalData] = useState(defaultData.hospital);

  const [appointmentData, setAppointmentData] = useState(defaultData.appointment);

  const query = useQuery();

  // fetch phone number from api

  const fetchData = async () => {
    console.log(query.get("appointmentID"));
    await fetch("http://86.11.53.197:5000/user/appointment", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("Atoken")} ${window.localStorage.getItem("Rtoken")}`,
      },
      body: JSON.stringify({ appointmentId: query.get("appointmentID") }),
    })
      .then((data) => data.json())
      .then((data) => {
        console.log("[fetchData]> ", data);
        setAppointmentData(data);
        setHospitalData(data.location);
      });
  };

  useEffect(() => {
    fetchData();
    return null;
  }, []);

  return (
    <AppointmentBaseContainer>
      <HospitalInfoCotainer>
        <Suspense fallback={<img src={StobhillImage} alt={hospitalData.name} />}>{getHospitalImageURL(appointmentData.location.identifier ?? "STOBHILL")}</Suspense>
        <HospitalInfo>
          <h2>{hospitalData.name ?? "Loading..."}</h2>
          <h4>{hospitalData.address ?? "Loading..." + " " + hospitalData.postcode ?? "Loading..."}</h4>
          <h4>{hospitalData.number ?? "Loading..."}</h4>
          <h4>{hospitalData.website ?? "Loading..."}</h4>
          {hospitalData.parking ? <h4>Parking Available</h4> : null}
          <h4>Public Transport {hospitalData.transport ?? "Loading..."}</h4>
          <p>{hospitalData.description ?? "Loading..."}</p>
        </HospitalInfo>
        <Map>
          <iframe src={hospitalData.mapHTML} title={hospitalData.name}></iframe>
        </Map>
      </HospitalInfoCotainer>
      <Row1>
        <Column1>
          <AppointmentInfo>
            <h2>Appointment Information</h2>
            <h4>Location: {appointmentData.location.name ?? "Loading..."}</h4>
            <h4>Date: {appointmentData.date ?? "Loading..."}</h4>
            <h4>Time: {appointmentData.time ?? "Loading..."}</h4>
            <h4>Ward: {appointmentData.ward ?? "Loading..."}</h4>
            <h4>Appointment: {appointmentData.title ?? "Loading..."}</h4>
          </AppointmentInfo>
          <AppointmentDirections>
            <h2>Directions to {appointmentData.location.name}</h2>
            <h4>Click on the "Directions" button on the map below</h4>
            <p>
              The map to the right can give directions to the appointment, directions can be given for car travel, walking or public transport. To get directions click on the map below and click the
              button labelled "Directions", You will then be taken to Google Maps. From Google Maps you can access the google maps directions.
            </p>

            <Map>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2237.285110543636!2d-4.220370683944117!3d55.892414488682846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x488843890455ebf9%3A0x9f08895e757b45c5!2sNew%20Stobhill%20Hospital!5e0!3m2!1sen!2suk!4v1647437668722!5m2!1sen!2suk"></iframe>
            </Map>
          </AppointmentDirections>
        </Column1>
        <Column2>
          <Suspense fallback={<h1>Loading...</h1>}>{getAppointment(appointmentData.type ?? "BLOOD_TEST")}</Suspense>
        </Column2>
      </Row1>
    </AppointmentBaseContainer>
  );
}
