import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';

// Context
import {ContextHandler} from './comp/ContextHandler/ContextHandler';

// Logic
import Auth, {Logout} from './comp/Logic/Logic';

//Layout

import PageHeader from './layout/PageHeader/PageHeader';
import PageFooter from './layout/PageFooter/PageFooter';

// Pages

import Landing from './pages/Landing/Landing';
import FAQ from './pages/FAQ/FAQ';
import Contact from './pages/Contact/Contact';
import Login from './pages/Login/Login';
import Signup from './pages/Signup/Signup';
import Account from './pages/Account/Account';
import AppointmentsPage from './pages/AppointmentsPage/AppointmentsPage';
import AppointmentBase from './pages/AppointmentBase/AppointmentBase';
import AppointmentForm from './pages/AppointmentForm/AppointmentForm';
import AppointmentHistory from './pages/AppointmentHistory/AppointmentHistory';

  

;

// const reAuthenticate = () => {
//   return fetch('http://localhost:5000/token', {
//             method: 'POST',
//             credentials: 'include',
//             headers: {
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify({'token': window.localStorage.getItem('Rtoken')})
//         })
//         .then(data => data.json())
//   // if(){
//     // Update token
//   // }else{
//     // Logout
//   // }
// };

export const LoggedContext = React.createContext();

function App() {
  
  return (
    <ContextHandler>
      <Router>
        <div className="App">
            <Auth />
            <PageHeader />
          <Routes>
            <Route path="/" exact element={<Landing />} />
            <Route path="/faq" exact element={<FAQ />} />
            <Route path="/help" exact element={<Contact />} />
            <Route path="/appointments" exact element={<AppointmentsPage />} />
            <Route path="/appointment" exact element={<AppointmentBase />} />
            <Route path="/new/appointment" exact element={<AppointmentForm />} />
            <Route path="/account" exact element={<Account />} />
            <Route path="/login" exact element={<Login />} />
            <Route path="/signup" exact element={<Signup />} />
            <Route path="/logout" exact element={<Logout />} />
            <Route path="/history" exact element={<AppointmentHistory />} />
            <Route element={<Landing />} />
          </Routes>
          <PageFooter />
        </div>
      </Router>
    </ContextHandler>
  );
}

export default App;
