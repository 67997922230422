import React from 'react';
import styled from '@emotion/styled';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import * as comp from '../../comp/Styled/StyledComponents';

const FooterContainerOuter = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #93BFB7;
    height: auto;
    padding: 30px 140px;
    z-index: -1;

    @media(max-width: 1000px) {
        padding: 2vw 4vw;
    }
    @media(max-width: 450px) {
        height: auto;
    }

`;
const FooterContainerInner = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: auto;
    width: 100%;
    flex: 5;

    @media(max-width: 1000px) {
        flex: 1;
        margin-bottom: 20px;
    }
    @media(max-width: 450px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20px 0;
    }
`;
const SocialContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 5vh;   
    justify-content: end;
    align-items: start;
    width: auto;
    & span{
        align-self: center;
        color: #ffffff;
        font-weight: 700;
        margin-right: 20px;
        width: 9ch;
    }
    @media(max-width: 1000px) {
        display: none;
    }
`;
const SocialContainerAlt = styled.div`
    display: flex;
    flex-direction: row;
    height: 5vh;   
    justify-content: end;
    align-items: start;
    width: auto;
    & span{
        align-self: center;
        color: #ffffff;
        font-weight: 700;
        margin-right: 20px;
        width: 9ch;
    }
    @media(min-width: 1000px) {
        display: none;
    }
`;
const SocialIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    margin-left: 1vmin;
    background: #387373;
    padding: 1px;
    max-height: 5vh;
    max-width: 5vh;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    & svg {
        height: 80%;
        width: 80%;
    }
    cursor: pointer;
    transition: color 200ms ease-in-out;
    &:hover{
        color: #2D3E40;
    }
`;


const FooterSpacer = styled.span`
    display: inline-block;
    border-top: 2px solid #72948E;
    width: 100%;
    height: 2px;
    flex: 1;

`;

const FooterMenu = styled.div`
    display: flex;
    flex-direction: column;
`;

const FooterMenuItem = styled.a`
    display: inline-block;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
    transition: color 200ms ease-in-out;
    &:hover{
        color: #2D3E40;
    }
`;

const Copyright = styled.div`
    color: #fff;
    flex: 1;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 500;
    font-size: 16px;
    @media(max-width: 450px) {
        margin-bottom: 20px;
    }
`;

const Pagefooter = () => {
    return (
        <FooterContainerOuter>
            <FooterContainerInner>
                <comp.WordLogo></comp.WordLogo>
                <FooterMenu>
                    <FooterMenuItem>HOME</FooterMenuItem>
                    <FooterMenuItem>HELP</FooterMenuItem>
                    <FooterMenuItem>FAQ</FooterMenuItem>
                    <FooterMenuItem>APPOINTMENTS</FooterMenuItem>
                </FooterMenu>
                <SocialContainer>
                    <span>
                        Follow us
                    </span>
                    <SocialIcon>
                        <FacebookIcon></FacebookIcon>
                    </SocialIcon>
                    <SocialIcon>
                        <TwitterIcon></TwitterIcon>
                    </SocialIcon>
                    <SocialIcon>
                        <InstagramIcon></InstagramIcon>
                    </SocialIcon>
                </SocialContainer>
            </FooterContainerInner>
                <FooterSpacer></FooterSpacer>
                
            <FooterContainerInner>
                <Copyright>© 2022, Paul Pirie.</Copyright>
                <SocialContainerAlt>
                    <span>
                        Follow us
                    </span>
                    <SocialIcon>
                        <FacebookIcon></FacebookIcon>
                    </SocialIcon>
                    <SocialIcon>
                        <TwitterIcon></TwitterIcon>
                    </SocialIcon>
                    <SocialIcon>
                        <InstagramIcon></InstagramIcon>
                    </SocialIcon>
                </SocialContainerAlt>
            </FooterContainerInner>
        </FooterContainerOuter>
    );
}

export default Pagefooter;
