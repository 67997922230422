import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import styled from '@emotion/styled';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';
import { Button } from '../../comp/Styled/StyledComponents';
import { AppointmentPast } from '../../comp/Logic/Logic';

const FAQContainer = styled.div`
    display: flex;
    width: 80%;
    padding: 4vmin;
    height: 100%;
    flex: 1;
    margin: 0 auto;
    @media(max-width: 1000px){
        Width: 90%;
    }
    @media(max-width: 450px){
        Width: 100%;
    }
`;
const FAQTable = styled.div`
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
`;
const FAQTableHeader = styled.div`
    margin: 0 auto;
    height: auto;
    display: flex;
    // flex: 1;
    flex-direction: row;
    width: 100%;
    border-radius: 10px 10px 0 0
    @media(max-width: 1000px){
    }
`;
const FAQTableHead = styled.h1`
    margin: 0 auto;
    padding: 1vmin;
    max-width: 100%;
    height: 6vmin;
    max-height: 100%;
    border-bottom: 1px solid #c9c9c9;
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 32px;
    font-weight: 700;
    color: #4D4D4D;
    text-align: left;
    & svg{
        transition: transform 0.5s;
    }
    @media(max-width: 1000px){
        max-height: unset;
        height: auto;
    }
    @media(max-width: 450px){
        font-size: 16px;
        font-weight: bold;
        color: #4D4D4D;
    }
`;
const FAQTableButtonHead = styled.h1`
    display: flex;
    margin: 0 auto;
    padding: 1vmin;
    max-width: 5%;
    height: 6vmin;
    max-height: 100%;
    border-bottom: 1px solid #c9c9c9;
    flex: 1;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
    text-align: left;
    justify-content: center;
    align-items: center;
    & svg:hover{
        color: #2D3E40;
    }
`;
const FAQTableBody = styled.div`
    margin: 10px auto 0 auto;
    height: auto;
    display: flex;
    // flex: 8;
    width: 100%;
    flex-direction: column;
    align-items: start;
`;
const FAQTableRowContainer = styled.div`
    margin: 0 auto 1vmin auto;
    height: auto;
    ${props => props.order === 0 ? 'margin-top: 1vmin;' : ''}
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    align-items: center;
    ${props => {
        if(props.order === (null || undefined)) return '';
        if(props.order === 0 || props.order % 2 === 0) return 'background-color: #ffffff; border-radius: 10px;';
        if(props.order % 2 !== 0) return 'background-color: #f3f3f3; border-radius: 10px;';
    }};
    &:hover{
        background-color: #c9c9c9;
    }
    
    @media(max-width: 1000px){
        margin: 0 auto 10px auto;
    }
`;
const FAQTableRow = styled.div`
    margin: 0 auto;
    height: auto;
    ${props => props.order === 0 ? 'margin-top: 1vmin;' : ''}
    display: flex;
    flex-direction: row;
    width: 100%;
    transition: all 0.5s;
    & * {  
        transition: all 0.5s;
    }
    flex: 1;
    align-items: center;
    background-color: ${props => props.opac ? 'none' : '#ffffff'}; 
    border-radius: 10px;
    &:hover{
        background-color: ${props => props.opac ? 'none' : '#c9c9c9'};
    }
    
    &#answer[open="true"] p {
        height: auto;
        padding: 1vmin;
        opacity: 1;
    }
    &#answer p {
        height: 0px;
        padding: 0px;
        opacity: 0;
    }
    
    @media(max-width: 1000px){
        padding: 2vmin;
        border-radius: 5px;
        &#answer[open="true"]{
            & p {
                height: auto;
                padding: 2vmin;
                opacity: 1;
            }
            height: auto;
            padding: 2vmin;
            opacity: 1;
        }
        &#answer {
            & p {
                height: 0px;
                padding: 0px;
                opacity: 0;
            }
            height: 0px;
            padding: 0px;
            opacity: 0;
        }
    }
`;
const FAQTableData = styled.p`
    margin: 0 auto;
    max-width: 100%;
    max-height: 100%;
    // max-height: 5ch;
    flex: 1;
    padding: 1vmin;
    font-size: 16px;
    font-weight: 400;
    color: #4D4D4D;
    @media(max-width: 450px){
        font-size: 12px;
        font-weight: 400;
        color: #4D4D4D;
    }
`;
const FAQTableButton = styled.div`
    margin: 0 auto;
    max-width: 5%;
    max-height: 100%;
    max-height: 5ch;
    flex: 1;
    margin: 1vmin 0;
    @media(max-width: 450px){
        max-width: 30px;
    }
`;


const getQuestions = (questions) => {
    console.log('[FAQs][GET][All] ', questions);
    return questions.map((data, index) => {
        return (
            <FAQTableRowContainer order={index} key={index} onClick={(e) => { 
                toggleQuestion(e, document.querySelector(`[order="${index}"]>div#answer`));
            }}>
                <FAQTableRow>
                    <FAQTableData>Q: {data.question}</FAQTableData>
                    <FAQTableButton>
                        <KeyboardArrowDownIcon />
                    </FAQTableButton>
                </FAQTableRow>
                <FAQTableRow id="answer" open={false}>
                    <FAQTableData>A: {data.answer}</FAQTableData>
                </FAQTableRow>
            </FAQTableRowContainer>
        )
    })
}

const toggleQuestion = (e, questionEl) => {
    console.log(questionEl)
    e.preventDefault();
    if(!e) throw new Error('Invalid event');
    if(!questionEl || !(questionEl instanceof HTMLElement)) throw new Error('Invalid Question');
    if(questionEl.hasAttribute('open') && questionEl.getAttribute('open') === 'true'){
        questionEl.setAttribute('open', false);
    }else{
        document.querySelectorAll('#answer').forEach(el => {
            if(el.hasAttribute('open')){
                el.setAttribute('open', false);
            }
        });
        questionEl.setAttribute('open', true);
    }
    
}


const FAQ = () => {

    const navigate = useNavigate();

    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        setQuestions([
            {
            question: 'How can I contact someone to ask for help?',
            answer: 'You can go to the Help page using the navigation bar and fill out the contact form. We will get back to you as soon as possible.'
            },
            {
            question: 'What if my appointment is cancelled?',
            answer: 'You can simple go to your appointment page and delete the appointment.'
            },
            {
            question: 'How do i change my account details?',
            answer: 'You can click on the account icon in the navigation bar and edit your, email address, name, and password.'
            },
    ]);
    },[])

    

    return (
        <FAQContainer>
            <FAQTable>
                <FAQTableHeader>
                    <FAQTableRow opac >
                        <FAQTableHead>Frequently Asked Question</FAQTableHead>                        
                        
                        <FAQTableButtonHead>
                            
                        </FAQTableButtonHead>
                    </FAQTableRow>
                </FAQTableHeader>
                <FAQTableBody>
                    {
                    questions.length < 1 ? 
                    (
                        <FAQTableRow>
                            <FAQTableData>No Questions</FAQTableData>
                        </FAQTableRow>
                    ):(
                        getQuestions(questions)
                        // <h1>Test</h1>
                    )
                    }
                </FAQTableBody>
            </FAQTable>
        </FAQContainer>
    );
}

export default FAQ;
