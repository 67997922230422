import { useState } from "react";
import styled from "@emotion/styled";
import loginImage from "../../assets/LoginImage.png";
import loginAltImage from "../../assets/LoginSmallImage.png";
import { WordLogo, Button } from "../../comp/Styled/StyledComponents";
import { Link, useNavigate } from "react-router-dom";

const SignupContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100vw;
  flex: 2;
  position: relative;
  @media (max-width: 1000px) {
    margin: 4vmin 0;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  background: url(${(props) => (props.big ? loginImage : loginAltImage)});
  @media (max-width: 1000px) {
    display: ${(props) => (props.big ? "none" : "inline-block")};
    width: ${(props) => (props.W ? props.W : "100%")};
    height: ${(props) => (props.H ? props.H : "100%")};
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin: 0 auto;
    max-width: 300px;
    max-height: 300px;
  }
`;

const FormContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Form = styled.form`
  width: 60%;
`;

const FormRow = styled.div`
  padding: ${(props) => (props.P ? props.P : "auto")};
  display: flex;
  justify-content: ${(props) => (props.layout ? props.layout : "center")};
  margin-top: 4vmin;

  @media (max-width: 550px) {
    flex-direction: column;
    margin-top: 0vmin;
    & > * {
      margin-top: 4vmin;
    }
  }
`;

const FormInputText = styled.input`
  width: ${(props) => (props.W ? props.W : "auto")};
  height: auto;
  padding: 10px 16px;
  border-radius: 7px;
  border: 2px solid #e9e9e9;
  margin: 0 auto;
  font-weight: 500;
  color: #c9c9c9;

  &::placeholder {
    color: #a7a7a7;
  }
`;

const FormInputCheck = styled.input`
  width: ${(props) => (props.W ? props.W : "auto")};
  height: auto;
  border-radius: 7px;
  border: 2px solid #e9e9e9;
  margin-right: 10px;
  font-weight: 500;
  color: #c9c9c9;

  &::placeholder {
    color: #a7a7a7;
  }
  @media (max-width: 550px) {
    width: 100%;
  }
`;

const SignupText = styled.p`
  width: 100%;
  text-align: center;
  color: #a1a1a1;
  font-weight: 300;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const InputLabel = styled.label`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: ${(props) => (props.W ? props.W : "auto")};
  margin: 0 auto;
  @media (max-width: 550px) {
    width: 100%;
  }
`;

const Signup = () => {
  let navigate = useNavigate();

  const handleSignupSubmit = (e) => {
    if (e) {
      e.preventDefault();
      const firstName = document.getElementById("f_name").value;
      const lastName = document.getElementById("l_name").value;
      const email = document.getElementById("email_address").value;
      const phone = document.getElementById("phone_number").value;
      const password = document.getElementById("p_word").value;
      const confirmPassword = document.getElementById("c_p_word").value;
      if (!firstName || !lastName || !email || !phone || !password || !confirmPassword) throw new Error("Please fill out all fields");
      if (password !== confirmPassword) throw new Error("Passwords do not match");
      // if(password.length < 6) throw new Error('Password must be at least 6 characters');
      if (!email.includes("@") || !email.includes(".")) throw new Error("Invalid email address");
      // if(!phone.includes('(') || !phone.includes(')') || !phone.includes('-')) throw new Error('Invalid phone number');
      const user = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: phone,
        password: password,
      };
      console.log(user);
      console.log("Form Submitted");
      // Fetch stuff

      return fetch("http://86.11.53.197:5000/user/register", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      })
        .then((data) => data.json())
        .then((data) => console.log(data))
        .then(navigate("/login"))
        .catch((err) => console.log(err));
    }
  };

  // RESIZE RENDER
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const handleResize = (e) => {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  };
  // window.addEventListener('resize', (e) => handleResize(e));
  // END RESIZE RENDER

  return (
    <SignupContainer>
      <ImageContainer big id={dimensions.height} />
      <FormContainer>
        <Form id="SignupForm" onSubmit={handleSignupSubmit}>
          <FormRow layout="center">
            <ImageContainer W="40vmin" H="40vmin" />
          </FormRow>
          <WordLogo dark center />
          <SignupText>Please log in or sign up for an account</SignupText>
          <FormRow layout="center">
            <InputLabel W="40%">
              First name
              <FormInputText type="text" W="100%" id="f_name" placeholder="First name" />
            </InputLabel>
            <InputLabel W="40%">
              Last name
              <FormInputText type="text" W="100%" id="l_name" placeholder="Last name" />
            </InputLabel>
          </FormRow>
          <FormRow layout="center">
            <InputLabel W="40%">
              Email address
              <FormInputText type="email" id="email_address" W="100%" placeholder="name@email.com" />
            </InputLabel>

            <InputLabel W="40%">
              Phone number
              <FormInputText type="number" id="phone_number" W="100%" placeholder="07123456789" />
            </InputLabel>
          </FormRow>
          <FormRow layout="center">
            <InputLabel W="40%">
              Password
              <FormInputText type="password" autoComplete="new-password" id="p_word" W="100%" placeholder="Password" />
            </InputLabel>
            <InputLabel W="40%">
              Confirm password
              <FormInputText type="password" autoComplete="new-password" id="c_p_word" W="100%" placeholder="Confirm password" />
            </InputLabel>
          </FormRow>
          <FormRow P="0 10%" layout="end">
            <Link to="/login">Already got an account? Login</Link>
          </FormRow>
          <FormRow P="0 10%" layout="end">
            <Button theme="dark" action={handleSignupSubmit(this)}>
              Signup
            </Button>
          </FormRow>
        </Form>
      </FormContainer>
    </SignupContainer>
  );
};

export default Signup;
