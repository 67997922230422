import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoggedContextUpdate } from "../ContextHandler/ContextHandler";

let whitelist = ["/landing", "/login", "/signup", "/faq", "/help", "/"];

const Auth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const updateLogged = useLoggedContextUpdate();

  const Authenticate = () => {
    if (!window.localStorage.getItem("Atoken") || window.localStorage.getItem("Atoken") === (null || undefined || "undefined")) {
      console.log(403);
      window.localStorage.removeItem("Atoken");
      window.localStorage.removeItem("Rtoken");
      updateLogged(false);
      alert("Session Invalid, Please Login Again");
      navigate("/login");
      return null;
    }

    return fetch("http://86.11.53.197:5000/auth", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("Atoken")} ${window.localStorage.getItem("Rtoken")}`,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        // console.log(data);
        if (data.authToken) {
          // alert("token updated")
          window.localStorage.setItem("Atoken", data.authToken);
        }
        if (data.status !== 200) {
          console.log(403);
          window.localStorage.removeItem("Atoken");
          window.localStorage.removeItem("Rtoken");
          updateLogged(false);
          alert("Session Invalid, Please Login Again");
          navigate("/login");
        }
      });
  };

  useEffect(() => {
    if (!whitelist.includes(location.pathname)) {
      console.log("Location changed " + location.pathname);
      if (window.localStorage.getItem("Atoken")) {
        Authenticate();
      } else {
        navigate("/login");
      }
    }
  }, [location]);
  return null;
};

export const formatDate = (date) => {
  if (!date) throw new Error("No date provided");
  let newDate = "";
  let dateArr = date.split("-");
  console.log(dateArr);
  let suffix = "";
  switch (dateArr[2]) {
    case "01":
      suffix = "st";
      break;
    case "02":
      suffix = "nd";
      break;
    case "03":
      suffix = "rd";
      break;
    case "21":
      suffix = "st";
      break;
    case "22":
      suffix = "nd";
      break;
    case "23":
      suffix = "rd";
      break;
    case "31":
      suffix = "st";
      break;
    default:
      suffix = "th";
      break;
  }
  let dateObj = new Date();
  dateObj.setFullYear(dateArr[0]);
  dateObj.setMonth(dateArr[1] - 1);
  dateObj.setDate(dateArr[2]);
  newDate = `${dateObj.getDate() + suffix} of ${dateObj.toLocaleString("en-GB", { month: "long" })} ${dateObj.getFullYear()}`;
  return newDate;
};

export const AppointmentPast = (appDate) => {
  let now = new Date();
  if (!appDate) throw new Error("AppointmentPast a date arguments");
  appDate = new Date(appDate);
  console.log({
    Appointment_Date: appDate,
    Now: now,
    inPast: appDate.setHours(0, 0, 0, 0) < now.setHours(0, 0, 0, 0),
  });
  return appDate.setHours(0, 0, 0, 0) < now.setHours(0, 0, 0, 0);

  // let appDateObj = appDate.split('-');
  // now = `${now.getFullYear()}-${(now.getMonth()+1).toString().length === 2 ? (now.getMonth()+1):("0"+(now.getMonth()+1))}-${now.getDate()}`;
  // let nowObj = now.toString().split('-');

  // console.log(nowObj);
  // console.log({
  //     Day: `${parseInt(appDateObj[2])} > ${parseInt(nowObj[2])}`,
  //     DayInPast: parseInt(appDateObj[2]) < parseInt(nowObj[2]),
  //     Month: `${appDateObj[1]} > ${nowObj[1]}`,
  //     MonthInPast: appDateObj[1] < nowObj[1],
  //     Year: `${appDateObj[0]} > ${nowObj[0]}`,
  //     YearInPast: appDateObj[0] < nowObj[0],
  //     DateTest: appDateObj[1] === nowObj[1] && appDateObj[2] < nowObj[2],
  // })
  // if(appDateObj[0] < nowObj[0]) return true;
  // console.log("Year Passed");
  // if(appDateObj[0] === nowObj[0] && appDateObj[1] < nowObj[1]) return true;
  // console.log("Year + Month Passed");
  // if(appDateObj[1] === nowObj[1] && appDateObj[2] < nowObj[2]) return true;
  // console.log("In Future");
  // return false;
};

export const Logout = () => {
  const navigate = useNavigate();
  window.localStorage.removeItem("Atoken");
  window.localStorage.removeItem("Rtoken");
  const updateLogged = useLoggedContextUpdate();
  updateLogged(false);
  navigate("/login");
  console.log("Logged Out");
  return null;
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export default Auth;
