import React from 'react';
import styled from '@emotion/styled';
import { WordLogo } from '../../comp/Styled/StyledComponents';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLoggedContext } from '../../comp/ContextHandler/ContextHandler';

const NavContainerOuter = styled.div`
    justify-self: start;
    display: flex;
    align-items: center;
    padding: 10px 140px;
    background: #93BFB7;
    height: 8vmin;
    min-height: 60px;
    max-width: 100vw;
    overflow-x: hidden;
    @media(max-width: 1000px){
        padding: 2vw 4vw;
    }
    @media(max-width: 450px){
        height: auto;
    }
`;

const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style-type: none;
    justify-content: end;
    flex: 5;
    @media(max-width: 1000px) {
        flex-direction: column;
        justify-content: center;
        padding: 0;
        max-width: 100%;
        &.bigScreen{
            display: none;
        }
    }
`;

const NavMenuItem = styled.div`
    display: flex;
    align-items: center;
    margin: 0 1vmin;
    color: #ffffff;
    font-weight: 700;
    cursor: pointer;
    transition: color 200ms ease-in-out;
    @media(max-width: 1000px){
        margin: 1vw 0;
    }

    & a{
        text-decoration: none;
        color: #ffffff;
        &:hover{
            color: #2D3E40;
        }
    }
    
`;

const AccountMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style-type: none;
    justify-content: end;
    flex: 1;
    @media(max-width: 1000px) {
        flex-direction: column;
        justify-content: center;
        padding: 0;
        max-width: 100%;
        &.bigScreen{
            display: none;
        }
    }
`;

const AccountMenuItem = styled.div`
    display: flex;
    align-items: center;
    margin: 0 1vmin;
    color: #ffffff;
    font-weight: 700;
    cursor: pointer;
    transition: color 200ms ease-in-out;
    @media(max-width: 1000px){
        margin: 1vw 0;
    }

    & a{
        text-decoration: none;
        color: #ffffff;
        &:hover{
            color: #2D3E40;
        }
    }
    
`;

const BurgerButton = styled.div`
    display: flex;
    align-items: center;
    color: #ffffff;
    cursor: pointer;
    @media(min-width: 1000px) {
        display: none;
    }
    &:hover{
        color: #2D3E40;
    }
`;

const BurgerMenu = styled.div`
    position: fixed;
    z-index: 10;
    & *{
        z-index: 11;
    }
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
    font-weight: 700;
    max-height: 100vh;
    height: 100vh;
    max-width: ${props => props.maxWidth || "300px"};
    width: 100%;
    background: #93BFB7;
    top: 0;
    right: 0;
    transition: transform 500ms ease-in-out;
    margin: 0;
    transform-origin: right;
    &.false{
        // transform: translateX(${props => props.maxWidth || "300px"});
        transform: scaleX(0);
    }
    @media(min-width: 1000px) {
        display: none;
    }
    @media(max-width: 450px) {
        max-width: 100vw;
        &.false{
            transform: scaleX(0);
        }
    }
    
`;

const Navbar = (props) => {

    const isLogged = useLoggedContext();

    const [menuActive, setMenuActive] = useState(false);

    return (
        <NavContainerOuter>
            <WordLogo size={'medium'} flex={1}></WordLogo>
            <NavMenu className={'bigScreen'}>
                <NavMenuItem><Link to="/">HOME</Link></NavMenuItem>
                <NavMenuItem><Link to="/help">HELP</Link></NavMenuItem>
                <NavMenuItem><Link to="/faq">FAQ</Link></NavMenuItem>
                <NavMenuItem><Link to="/appointments">APPOINTMENTS</Link></NavMenuItem>
            </NavMenu>
            { !isLogged ? (
                <AccountMenu className={'bigScreen'}> 
                    <AccountMenuItem><Link to="/login" >LOGIN</Link></AccountMenuItem>
                    <AccountMenuItem><Link to="/signup" >SIGNUP</Link></AccountMenuItem>
                </AccountMenu>
                ) : (
                <AccountMenu className={'bigScreen'}>
                    <AccountMenuItem><Link to="/account" ><AccountCircleIcon/></Link></AccountMenuItem>
                    <AccountMenuItem><Link to="/logout" >LOGOUT</Link></AccountMenuItem>
                </AccountMenu>
                )}
            <BurgerButton onClick={() => {setMenuActive(!menuActive)}} ><MenuIcon></MenuIcon></BurgerButton>
            
            <BurgerMenu className={menuActive} onClick={() => menuActive ? setMenuActive(!menuActive) : ''}>
            <BurgerButton onClick={() => {setMenuActive(!menuActive)}} ><MenuIcon></MenuIcon></BurgerButton>
            <NavMenu>
                <NavMenuItem><Link to="/">HOME</Link></NavMenuItem>
                <NavMenuItem><Link to="/help">HELP</Link></NavMenuItem>
                <NavMenuItem><Link to="/faq">FAQ</Link></NavMenuItem>
                <NavMenuItem><Link to="/appointments">APPOINTMENTS</Link></NavMenuItem>
            </NavMenu>
                { !isLogged ? (
                <AccountMenu>
                    <AccountMenuItem><Link to="/login" >LOGIN</Link></AccountMenuItem>
                    <AccountMenuItem><Link to="/signup" >SIGNUP</Link></AccountMenuItem>
                </AccountMenu>
                ) : (
                <AccountMenu>
                    <AccountMenuItem><Link to="/account" ><AccountCircleIcon/></Link></AccountMenuItem>
                    <AccountMenuItem><Link to="/logout" >LOGOUT</Link></AccountMenuItem>
                </AccountMenu>
                )}
            </BurgerMenu>
        </NavContainerOuter>
    );
}

export default Navbar;
