import React, { useContext, useState } from "react";

const LoggedContext = React.createContext();
const LoggedContextUpdate = React.createContext();
const UserContext = React.createContext();
const UserContextUpdate = React.createContext();
const UserContextRefresh = React.createContext();

export const ContextHandler = ({ children }) => {
  const [isLogged, setLogged] = useState(window.localStorage.getItem("Atoken") ? true : false);
  const [userData, setUserData] = useState({
    email: "xxxxxx@xxxxx.xx.xx",
  });

  const toggleLogged = (val = !isLogged) => {
    setLogged(val);
  };

  const getUserData = async () => {
    await fetch("http://86.11.53.197:5000/user", {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("Atoken")} ${window.localStorage.getItem("Rtoken")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setUserData(data.userData);
        console.log("[Informed Patient][User][Fetch]> ", data);
      })
      .catch((err) => console.warn("[Informed Patient][User][Fetch][Error]> ", err));
  };

  return (
    <LoggedContext.Provider value={isLogged}>
      <LoggedContextUpdate.Provider value={toggleLogged}>
        <UserContext.Provider value={userData}>
          <UserContextUpdate.Provider value={setUserData}>
            <UserContextRefresh.Provider value={getUserData}>{children}</UserContextRefresh.Provider>
          </UserContextUpdate.Provider>
        </UserContext.Provider>
      </LoggedContextUpdate.Provider>
    </LoggedContext.Provider>
  );
};

export const useLoggedContext = () => {
  return useContext(LoggedContext);
};
export const useLoggedContextUpdate = () => {
  return useContext(LoggedContextUpdate);
};
export const useUserContext = () => {
  return useContext(UserContext);
};
export const useUserContextUpdate = () => {
  return useContext(UserContextUpdate);
};
export const useUserContextRefresh = () => {
  return useContext(UserContextRefresh);
};
