/* eslint-disable no-throw-literal */
import { useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import styled from "@emotion/styled";
import { WordLogo, Button } from "../../comp/Styled/StyledComponents";
import CloseIcon from "@material-ui/icons/Close";
import { useUserContext, useUserContextUpdate, useUserContextRefresh } from "../../comp/ContextHandler/ContextHandler";

const AccountContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1;
  font-size: 8px;
  @media (max-width: 1000px) {
    font-size: 7px;
  }
  @media (max-width: 700px) {
    font-size: 5px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  width: ${(props) => (props.W ? props.W : "auto")};
  @media (max-width: 1000px) {
    width: 80%;
    padding: 5vmin;
  }
  @media (max-width: 700px) {
    width: 95%;
    padding: 0px;
  }
  @media (max-width: 300px) {
    flex-direction: column;
  }
`;

const AccountForm = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  background: #ffffff;
  width: 60%;
  margin: 3vmin 0;
  border-radius: 20px;
  padding: 10vmin;
  flex: 1;
  justify-content: center;
  align-items: center;
  @media (max-width: 1000px) {
    width: 80%;
    padding: 5vmin;
  }
  @media (max-width: 700px) {
    width: 90%;
    padding: 20px;
  }
  @media (max-width: 300px) {
    padding: 10px;
  }
`;
const AccountFormSection = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    background: ${(props) => (props.clear ? "none" : "#f1f1f1")};
    width: 80%;
    margin: 2vmin 0;
    border-radius: 20px;
    padding: 2vmin;
    flex: 1;
    justify-content: center;
    & h1 {
        font-size: 5em;
    }
    @media (max-width: 1000px){
        padding: 5vmin;
    }
    @media (max-width: 700px){
        width: 95%;
        padding: 20px;
    }
    @media (max-width: 300px){
        padding: 10px;
}a
`;

const AccountFormInput = styled.form`
  display: flex;
  position: relative;
  flex-direction: row;
  width: 100%;
  flex: 1;
  margin: 2vmin 0;
  & h2 {
    font-size: 2em;
  }
  & label {
    font-size: 1.5em;
  }
  & button {
    align-self: center;
  }

  @media (max-width: 1000px) {
  }
  @media (max-width: 700px) {
    width: 95%;
  }
  @media (max-width: 300px) {
    flex-direction: column;
  }
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: ${(props) => (props.W ? props.W : "auto")};
  margin: 0 auto;
  @media (max-width: 550px) {
    width: 100%;
  }
`;

const ModalContainer = styled.div`
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10;
  justify-content: center;
  align-items: center;
  top: 0;
  &[open] {
    display: flex;
  }
`;

const Modal = styled.form`
  display: none;
  position: fixed;
  width: 60vmin;
  height: auto;
  background: #ffffff;
  z-index: 10;
  justify-content: start;
  align-items: center;
  border-radius: 20px;
  padding: 3vmin;
  flex-direction: column;
  font-size: 2em;
  &[open] {
    display: flex;
  }
  & > svg {
    position: absolute;
    top: 0;
    right: 0;
    margin: 2vmin;
  }
  @media (max-width: 1000px) {
    width: 80vmin;
  }
  @media (max-width: 700px) {
    width: 90vmin;
    padding: 20px;
  }
`;

const FormInputText = styled.input`
  width: ${(props) => (props.W ? props.W : "auto")};
  height: auto;
  padding: 10px 16px;
  border-radius: 7px;
  border: 2px solid #e9e9e9;
  margin: 2vmin auto;
  font-weight: 500;
  color: #c9c9c9;

  &::placeholder {
    color: #a7a7a7;
  }
`;

const Text = styled.p`
  width: 80%;
  text-align: center;
  color: #696969;
  font-weight: 500;
  font-size: 2em;
`;

const closeModals = () => {
  let modals = document.querySelectorAll("[open]");
  if (!modals || modals.length === 0) throw new Error("Invalid modals");
  modals.forEach((modal) => {
    modal.removeAttribute("open");
  });
};

const openModal = (e, modal) => {
  console.log(modal);
  e.preventDefault();
  if (!e) throw new Error("Invalid event");
  if (!modal || !(modal instanceof HTMLElement)) throw new Error("Invalid modals");
  document.querySelector("#modalContainer").setAttribute("open", true);
  modal.setAttribute("open", true);
};

const Account = () => {
  // const [user, setUser] = useState({});
  const user = useUserContext();
  const setUser = useUserContextUpdate();
  const refreshUser = useUserContextRefresh();
  let location = useLocation();

  useEffect(() => {
    refreshUser();
  }, []);

  useEffect(() => {
    refreshUser();
  }, [location]);

  const handleFormSubmit = (e) => {
    if (e) {
      e.preventDefault();
      const type = document.querySelector("form[open]").getAttribute("id").split("__")[1];
      const data = document.querySelectorAll("form[open] input");
      let payload = {};
      const validTypes = ["name", "email", "password"];
      // console.log({
      //     type: type,
      //     typeValid: validTypes.includes(type),
      //     data: data,
      //     dataValid: data !== (null || undefined)
      // });
      if (!validTypes.includes(type)) throw new Error("Invalid form type");
      if (data === (null || undefined)) throw new Error("Invalid form data");
      if (type === "name") {
        payload = {
          firstName: data[0].value,
          lastName: data[1].value,
        };
      }
      if (type === "email") {
        payload = {
          email: data[0].value,
        };
      }
      if (type === "password") {
        if (data[1].value !== data[2].value) throw new Error("Passwords do not match");

        payload = {
          currentPassword: data[0].value,
          newPassword: data[1].value,
        };
      }
      // Fetch stuff
      return fetch(`http://86.11.53.197:5000/user/${type}`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("Atoken")} ${window.localStorage.getItem("Rtoken")}`,
        },
        body: JSON.stringify(payload),
      })
        .then((data) => {
          let dataObj = { res: data, data: undefined };
          return data
            .json()
            .then((json) => (dataObj.data = json))
            .then(() => {
              return dataObj;
            });
        })
        .then((dataObj) => {
          if (!dataObj.res.ok) throw { error: dataObj.data.error ?? "Unknown Error" };
          return dataObj.data;
        })
        .then(closeModals())
        .then(refreshUser())
        .catch((err) => {
          if (err.error) alert(err.error);
        });
    }
  };

  return (
    <AccountContainer>
      <AccountForm>
        <WordLogo center dark />
        <AccountFormSection clear>
          <h1>{`${user.firstName} ${user.lastName}`}</h1>
        </AccountFormSection>
        <ButtonContainer W="80%">
          <Button theme="dark" M="1vmin 0">
            <Link to="/appointments">Appointments</Link>
          </Button>
          <Button theme="dark" M="1vmin 0">
            <Link to="/history">History</Link>
          </Button>
        </ButtonContainer>
        <AccountFormSection>
          <AccountFormInput onSubmit={(e) => openModal(e, document.querySelector(`#modal__name`))}>
            <Label W="100%">
              Name
              <h2>{`${user.firstName} ${user.lastName}`}</h2>
            </Label>
            <Button theme="dark">Edit</Button>
          </AccountFormInput>
          <AccountFormInput onSubmit={(e) => openModal(e, document.querySelector(`#modal__email`))}>
            <Label W="100%">
              Email
              <h2>{user.email.length > 10 ? user.email.substring(0, 15) + "..." : user.email}</h2>
              {/* <h2>{user.email}</h2> */}
            </Label>
            <Button theme="dark">Edit</Button>
          </AccountFormInput>
          <AccountFormInput onSubmit={(e) => openModal(e, document.querySelector(`#modal__password`))}>
            <Label W="100%">
              Password
              <h2>*****</h2>
            </Label>
            <Button theme="dark" onClick={() => openModal(document.querySelector(`[isFor=password]`))}>
              Edit
            </Button>
          </AccountFormInput>
        </AccountFormSection>

        <Button theme="dark" M="20px 10% 0 auto">
          <Link to="/logout">Log out</Link>
        </Button>
      </AccountForm>
      <ModalContainer id="modalContainer">
        <Modal id="modal__name" onSubmit={(e) => handleFormSubmit(e)}>
          <CloseIcon
            onClick={(e) => {
              closeModals();
            }}
          />
          <WordLogo flex="unset" center dark />
          <Text>You can use this form to change the first and last name stored on your account.</Text>
          <Label W="80%">
            First Name
            <FormInputText type="text" defaultValue={user.firstName} id="firstName" placeholder="First Name" W="100%" />
          </Label>
          <Label W="80%">
            Last Name
            <FormInputText type="text" defaultValue={user.lastName} id="lastName" placeholder="Last Name" W="100%" />
          </Label>
          <Button theme="dark" M="auto 0 0 auto">
            Change Name
          </Button>
        </Modal>
        <Modal id="modal__email" onSubmit={(e) => handleFormSubmit(e)}>
          <CloseIcon
            onClick={(e) => {
              closeModals();
            }}
          />
          <WordLogo flex="unset" center dark />
          <Text>You can use this form to change the email address linked to your account.</Text>
          <Label W="80%">
            Email Address
            <FormInputText type="email" defaultValue={user.email} id="email" placeholder="Email Address" W="100%" />
          </Label>
          <Button theme="dark" M="auto 0 0 auto">
            Change Email
          </Button>
        </Modal>
        <Modal id="modal__password" onSubmit={(e) => handleFormSubmit(e)}>
          <CloseIcon
            onClick={(e) => {
              closeModals();
            }}
          />
          <WordLogo flex="unset" center dark />
          <Text>You can use this form to change the password for your account.</Text>
          <Label W="80%">
            Current Password
            <FormInputText type="password" id="current-password" placeholder="Current Password" W="100%" />
          </Label>
          <Label W="80%">
            New Password
            <FormInputText type="password" id="new-password" placeholder="New Password" W="100%" />
          </Label>
          <Label W="80%">
            Confirm New Password
            <FormInputText type="password" id="confirm-password" placeholder="Confirm New Password" W="100%" />
          </Label>
          <Button theme="dark" M="auto 0 0 auto">
            Change Password
          </Button>
        </Modal>
      </ModalContainer>
    </AccountContainer>
  );
};

export default Account;
