import React from 'react';
import Navbar from '../NavigationBar/Navbar';

const Pageheader = () => {
    return (
        <Navbar></Navbar>
    );
}

export default Pageheader;
