import React from 'react';
import styled from '@emotion/styled';
import { WordLogo, Button } from '../../comp/Styled/StyledComponents';
import HeroImageURL from '../../assets/hero_image.png';
import RenderImageURL from '../../assets/phone_render.png';
import CTAImageURL from '../../assets/account_image.png';
import { Link } from 'react-router-dom';

const LandingContainer = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    font-size: 8px;
    @media (max-width: 1000px){
        font-size: 7px;
    }
    @media (max-width: 700px){
        font-size: 4px;
    }
`;

const LandingSection = styled.div`
    display: flex;
    width: 100%;
    height: auto;
    padding: 5vmin 0;
    &.hero{
        padding-left: 10vmin;
        padding-right: 10vmin;
        min-height: 80vh;
        @media (max-width: 700px){
            flex-direction: column-reverse;
        }
    }
    &.render{
        padding: 0 10vmin;
        min-height: 60vh;
        @media (max-width: 700px){
            flex-direction: column-reverse;
        }
    }
    &.cta{
        min-height: 60vh;
        padding: 5vmin 10vmin 0 0;
        background: #E4F2E7;
        @media (max-width: 700px){
            flex-direction: column;
        }
    }
`;
const HeroText = styled.div`
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: end;
    flex: 1;
    padding-bottom: 5vmin;
    @media (max-width: 700px){
        flex: unset;
    }
    h1{
        font-size: 6em;
        font-weight: 900;
        color: #72948E;
        margin-top: 0px;
        flex: unset;
        &.wordLogo{
            text-align: left;
        }
    }
    h2{
        color: #97A6A0;
        font-weight: 700;
        font-size: 4em;
    }
    h5{
        color: #387373;
        font-weight: 400;
        font-size: 2em;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }

`;
const RenderText = styled.div`
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    padding-bottom: 5vmin;
    @media (max-width: 700px){
        flex: unset;
    }
    h1{
        font-size: 6em;
        font-weight: 900;
        color: #72948E;
        margin-top: 0px;
        flex: unset;
    }
    h2{
        color: #97A6A0;
        font-weight: 700;
        font-size: 4em;
    }
`;

const CTAText = styled.div`
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    flex: 1;
    padding: 5vmin;
    @media (max-width: 700px){
        flex: unset;
    }
    h1{
        font-size: 6em;
        font-weight: 900;
        color: #72948E;
        margin-top: 0px;
        flex: unset;
    }
    h2{
        color: #97A6A0;
        font-weight: 700;
        font-size: 4em;
    }
`;
const HeroImage = styled.div`
    flex: 1;
    background: url(${HeroImageURL}) no-repeat center center;
    background-size: contain;
    @media (max-width: 700px){
        // display: none;
    }
`;
const RenderImage = styled.div`
    flex: 1;
    background: url(${RenderImageURL}) no-repeat center center;
    background-size: contain;
`;
const CTAImage = styled.div`
    flex: 1;
    background: url(${CTAImageURL}) no-repeat center center;
    background-size: contain;
`;

const CTA = styled.div`
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5vmin 10vmin;
    flex: 1;
    background: #93BFB7;
    @media (max-width: 1000px){
        padding: 5vmin;
        & h1{
            font-size: 3em;
            padding-bottom: 20px;
        }
    }
    @media (max-width: 700px){
        flex-direction: column;
    }
    & h1{
        color: #ffffff;
        // flex: 1;
        margin: 0 2vmin;
        
        & span{
            color: #2D3E40;
        }
        @media (max-width: 700px){
            & br{
                display: none;
            }
        }
        &.wordLogo{
            flex: unset;
            
        @media (max-width: 700px){
            display: none;

            & br{
                display: none;
            }
        }
        }
    }
`;

const Landing = () => {
    return (
        <LandingContainer>
            <LandingSection className="hero">
                <HeroText>
                    <h1>Keeping you in the loop</h1>
                    <h2>Attending an appointment shouldn't be filled with worry and unanswered questions</h2>
                    <h5>We're here to help you become an</h5>
                    <WordLogo dark />
                </HeroText>
                <HeroImage />
            </LandingSection>

            
            <LandingSection>
                <CTA>
                    <WordLogo />
                    <h1>GET THE INFORMATION <span>YOU</span> NEED TO<br /> TACKLE <span>YOUR</span> APPOINTMENT</h1>
                    <Button theme="round"><Link to="/signup">SIGN UP</Link></Button>
                </CTA>
            </LandingSection>


            <LandingSection className="render">
                <RenderText>
                    <h1>Keeping you in the loop</h1>
                    <h2>Attending an appointment shouldn't be filled with worry and unanswered questions</h2>
                </RenderText>
                <RenderImage />
            </LandingSection>


            <LandingSection className="cta">
                <CTAImage />
                <CTAText>
                    <h1>Sign up for an account today!</h1>
                    <h2>With our simple appointment manager you are in the loop and in control.</h2>
                    <Button theme="round-dark" M="1vmin 0 0 auto" ><Link to="/signup">SIGN UP</Link></Button>
                </CTAText>
            </LandingSection>

        </LandingContainer>
    );
};


export default Landing;
