import styled from '@emotion/styled';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';


export const WordLogo = (props) => {
    const getSize = (size) => {
        switch(size){
            case 'small':
                return '18px';
                
            case 'medium':
                return '18px';
            
            case 'large':
                return '32px';

            default:
                return '32px';
        }
    }
    const WordLogoH1 = styled.h1`
        font-weight: 900;
        color: ${props.dark ? '#97A6A0' : '#ffffff'} !important;
        cursor: pointer;
        font-size: ${getSize(props.size)};
        flex: ${props.flex !== (null||undefined) ? props.flex : 1};
        ${props.center ? 'text-align: center;': ""}

        & span {
            color: #2D3E40 !important;
        }
        @media(max-width: 1000px) {
            font-size: 24px;
        }
        
        @media(max-width: 450px) {
            font-size: 24px;
        }
        @media(max-width: 350px) {
            text-align: center;
        }
    `;

    return (
        <WordLogoH1 className="wordLogo" onClick={() => {}}>INFORMED <span>PATIENT</span></WordLogoH1>
    );
};

export const Button = (props) => {
    const getSize = (size) => {
        switch(size){
            case 'small':
                return '18px';
                
            case 'medium':
                return '24px';
            
            case 'large':
                return '32px';

            default:
                return '18px';
        }
    }

    const getAction = () => {
        return ( 
            props.action ? `onClick={${props.action}}` : ''
        );
    }

    let buttonThemes = [];
    buttonThemes['light'] = {
            color: '#ffffff',
            background: '#97A6A0',
            border: '0px solid #000000',
            padding: '8px 16px',
            font_weight: 'inherit'
        };

    buttonThemes['round'] = {
        color: '#2D3E40',
        background: '#E4F2E7',
        border: '0px solid #000000',
        padding: '8px 16px',
        border_radius: '50px',
        font_weight: '900'
    };

    buttonThemes['round-dark'] = {
        color: '#2D3E40',
        background: '#93BFB7',
        border: '0px solid #000000',
        padding: '8px 16px',
        border_radius: '50px',
        font_weight: '900'
    };
    
    buttonThemes['dark'] = {
            color: '#ffffff',
            background: '#387373',
            border: '0px solid #000000',
            padding: '8px 16px',
            font_weight: 'inherit'
        }

    buttonThemes['clear'] = {
        color: '#4D4D4D',
        background: 'none',
        border: 'none',
        padding: '8px 0px 8px 16px',
        margin_left: 'auto',
        font_weight: 'inherit'
    }

    const ButtonComp = styled.button`
        font-weight: 700;
        color: ${props.theme ? buttonThemes[props.theme].color : '#0000ff'};
        ${props.color ? ('color: ' + props.color + ';'):''}
        cursor: pointer;
        font-size: ${getSize(props.size)};
        font-weight: ${props.theme ? buttonThemes[props.theme].font_weight : 'inherit'};
        padding: ${props.theme ? buttonThemes[props.theme].padding : '5px 10px'};
        background: ${props.theme ? buttonThemes[props.theme].background : '#00ff00'};
        border-radius: ${props.theme && buttonThemes[props.theme].border_radius ? buttonThemes[props.theme].border_radius : '4px'};
        border: ${props.theme ? buttonThemes[props.theme].border : 'none'};
        margin: ${props.M ? props.M : '0px;'};
        ${props.theme === "clear" ? ("margin-left: "+buttonThemes[props.theme].margin_left+";") : ''}
        display: flex;
        justify-content: ${props.pos ? props.pos : 'center'};
        align-items: center;

        & *{
            text-decoration: none;
            color: ${props.theme ? buttonThemes[props.theme].color : '#ffffff'};
        }

        @media(max-width: 1000px) {
        }
        
        @media(max-width: 450px) {
        }
        @media(max-width: 350px) {
            text-align: center;
        }
    `;
    

    return (
        <ButtonComp { ...getAction() } >{props.children ? props.children : 'PLACEHOLDER'}{props.theme === "clear" ? <KeyboardArrowRightIcon/> : ''}</ButtonComp>
    );
};