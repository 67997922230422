import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import RefreshIcon from "@material-ui/icons/Refresh";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "../../comp/Styled/StyledComponents";
import { AppointmentPast, useWindowDimensions, formatDate } from "../../comp/Logic/Logic";

const AppointmentsTableContainer = styled.div`
  display: flex;
  min-width: 100%;
  padding: 4vmin;
  height: 100%;
  width: 100%;
  flex: 1;
`;
const AppointmentsTable = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const AppointmentsTableHeader = styled.div`
  margin: 0 auto;
  height: auto;
  display: flex;
  // flex: 1;
  flex-direction: row;
  width: 100%;
  border-radius: 10px 10px 0 0;
`;
const AppointmentsTableHead = styled.h1`
  margin: 0 auto;
  padding: 1vmin;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  border-bottom: 1px solid #c9c9c9;
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  & svg {
    transition: transform 0.5s;
  }
  &.mobile {
    display: none;
  }
  @media (max-width: 768px) {
    &.desktop {
      display: none;
    }
    &.mobile {
      display: inline-block;
    }
  }
`;
const AppointmentsTableButtonHead = styled.h1`
  display: flex;
  margin: 0 auto;
  padding: 1vmin;
  max-width: 5%;
  height: auto;
  max-height: 100%;
  border-bottom: 1px solid #c9c9c9;
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  text-align: left;
  justify-content: center;
  align-items: center;
  & svg:hover {
    color: #2d3e40;
  }
  @media (max-width: 450px) {
    margin: 0 10px;
  }
`;
const AppointmentsTableBody = styled.div`
  margin: 0 auto;
  height: auto;
  display: flex;
  // flex: 8;
  width: 100%;
  flex-direction: column;
  align-items: start;
`;
const AppointmentsTableRow = styled.div`
  margin: 0 auto;
  height: auto;
  ${(props) => (props.order === 0 ? "margin-top: 1vmin;" : "")}
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
  align-items: center;
  ${(props) => {
    if (props.order === (null || undefined)) return "";
    if (props.order === 0 || props.order % 2 === 0) return "background-color: #ffffff; border-radius: 10px;";
    if (props.order % 2 !== 0) return "background-color: #f3f3f3; border-radius: 10px;";
  }};
  padding: 1vmin 2vmin;
  &:hover {
    background-color: #c9c9c9;
  }
  @media (max-width: 1000px) {
    background-color: #ffffff;
    margin-bottom: 20px;
    flex-direction: column;
    ${(props) => (props.fixed_dir ? "flex-direction: row;" : "")}
    padding: 20px;
    &:hover {
      background-color: #ffffff;
    }
  }
`;
const AppointmentsTableData = styled.p`
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
  // max-height: 5ch;
  flex: 1;
  padding: 1vmin;
`;
const AppointmentsTableButton = styled.p`
  margin: 0 auto;
  max-width: 5%;
  max-height: 100%;
  max-height: 5ch;
  flex: 1;
  margin: 1vmin 0;
`;

const AppointmentsMobileRow = styled.div`
  margin: 0 auto;
  height: auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
  align-items: center;
  padding: 1vmin 2vmin;
  border-radius: 5px;
  ${(props) => (props.odd ? "background-color: #f1f1f1;" : "")};
  justify-content: space-between;
  &.no-horizontal-padding {
    padding-right: 0;
    padding-left: 0;
  }
  & p:last-of-type {
    text-align: right;
  }
`;

const AppointmentsMobileColumn = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 70%;
  flex: 1;
  align-items: start;
  word-wrap: break-word;
  & p {
    text-align: left !important;
    margin-left: 0px;
  }
  & p:first-of-type {
    padding-bottom: 0.5vmin;
  }
  & p:last-of-type {
    padding-top: 0;
  }
`;

const AppointmentsMobileData = styled.p`
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
  flex: 1;
  padding: 1vmin;
  color: #4d4d4d;
  font-size: 12px;
  &.title {
    font-weight: bold;
    font-size: 22px;
    color: #2d3e40;
  }
`;
const AppointmentsMobileButton = styled.p`
  margin: 0 auto;
  max-height: 100%;
  margin: 1vmin 0;
`;

const AppointmentsHistory = () => {
  const { width, height } = useWindowDimensions();
  console.log(width, height);

  const getAppointments = (appointments) => {
    const now = new Date();
    const futureAppointments = [];
    // console.log(AppointmentPast(123));
    appointments.forEach((appointment, index) => {
      if (AppointmentPast(appointment.date, now)) futureAppointments.push(appointment);
    });
    console.log("[Appointments][GET][All] ", appointments);
    console.log("[Appointments][GET][Future] ", futureAppointments);
    return futureAppointments.map((data, index) => {
      if (width > 1000) {
        return (
          <AppointmentsTableRow order={index} key={index}>
            <AppointmentsTableData>{data.title}</AppointmentsTableData>
            <AppointmentsTableData>{data.location.name ?? data.location}</AppointmentsTableData>
            <AppointmentsTableData>{formatDate(data.date)}</AppointmentsTableData>
            <AppointmentsTableData>{data.time}</AppointmentsTableData>
            <AppointmentsTableButton>
              <Button theme="dark" center>
                <Link to={`/appointment?appointmentID=${data._id}`}>View</Link>
              </Button>
            </AppointmentsTableButton>
            <AppointmentsTableButton></AppointmentsTableButton>
          </AppointmentsTableRow>
        );
      } else {
        return (
          <AppointmentsTableRow order={index} key={index}>
            <AppointmentsMobileRow className="no-horizontal-padding">
              <AppointmentsMobileColumn>
                <AppointmentsMobileData className="title">{data.title}</AppointmentsMobileData>
                <AppointmentsMobileData>{data.location.name ?? data.location}</AppointmentsMobileData>
              </AppointmentsMobileColumn>
              <AppointmentsMobileButton>
                <Button theme="clear" center>
                  <Link to={`/appointment?appointmentID=${data._id}`}>View</Link>
                </Button>
              </AppointmentsMobileButton>
            </AppointmentsMobileRow>
            <AppointmentsMobileRow odd>
              <AppointmentsMobileData>Location</AppointmentsMobileData>
              <AppointmentsMobileData>{data.location.name ?? data.location}</AppointmentsMobileData>
            </AppointmentsMobileRow>
            <AppointmentsMobileRow>
              <AppointmentsMobileData>Date</AppointmentsMobileData>
              <AppointmentsMobileData>{formatDate(data.date)}</AppointmentsMobileData>
            </AppointmentsMobileRow>
            <AppointmentsMobileRow odd>
              <AppointmentsMobileData>Time</AppointmentsMobileData>
              <AppointmentsMobileData>{data.time}</AppointmentsMobileData>
            </AppointmentsMobileRow>
          </AppointmentsTableRow>
        );
      }
    });
  };

  const navigate = useNavigate();

  let rotate = 0;

  useEffect(() => {
    fetchAppointments();
  }, []);

  const [appointments, setAppointments] = useState([]);

  const fetchAppointments = async () => {
    await fetch("http://86.11.53.197:5000/user/appointments", {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("Atoken")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setAppointments(data);
        console.log(data);
      })
      .catch((err) => console.warn("[Informed Patient][Appointment][Fetch]> ", err));
  };

  return (
    <AppointmentsTableContainer>
      <AppointmentsTable>
        <AppointmentsTableHeader>
          <AppointmentsTableRow fixed_dir="row">
            <AppointmentsTableHead className="mobile">Appointments</AppointmentsTableHead>
            <AppointmentsTableHead className="desktop">Title</AppointmentsTableHead>
            <AppointmentsTableHead className="desktop">Location</AppointmentsTableHead>
            <AppointmentsTableHead className="desktop">Date</AppointmentsTableHead>
            <AppointmentsTableHead className="desktop">Time</AppointmentsTableHead>
            <AppointmentsTableButtonHead
              onClick={() => {
                rotate = rotate + 360;
                console.log(rotate);
                document.querySelector("#refreshButton").style.transform = `rotate(${rotate}deg)`;
                fetchAppointments();
              }}
            >
              {/* <Link to="/new/appointment">New</Link> */}
              <RefreshIcon id="refreshButton" />
            </AppointmentsTableButtonHead>
            <AppointmentsTableButtonHead
              onClick={() => {
                navigate("/new/appointment");
              }}
            >
              {/* <Link to="/new/appointment">New</Link> */}
              <AddIcon />
            </AppointmentsTableButtonHead>
          </AppointmentsTableRow>
        </AppointmentsTableHeader>
        <AppointmentsTableBody>
          {appointments.length < 1 ? (
            <AppointmentsTableRow>
              <AppointmentsTableData>No Appointments</AppointmentsTableData>
            </AppointmentsTableRow>
          ) : (
            getAppointments(appointments)
            // <h1>Test</h1>
          )}
        </AppointmentsTableBody>
      </AppointmentsTable>
    </AppointmentsTableContainer>
  );
};

export default AppointmentsHistory;
