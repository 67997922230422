import React from 'react';
import styled from '@emotion/styled';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import { Button } from '../../comp/Styled/StyledComponents';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import PinIcon from '@material-ui/icons/Room';

const ContactContainer = styled.div`
    display: flex;
    height: 100%;
    flex: 2;
    margin: 3vmin 4vmin;
    position: relative;
    border-radius: 10px;
    box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.3);
    @media (max-width: 1000px){
        flex-direction: column;
    }
`;
const ContactFormContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 0 10px 10px 0;
    background-color: #ffffff;
    z-index: 2;
    flex: 1;
    @media (max-width: 1000px){
        border-radius: 0 0 10px 10px;
    }
`;
const ContactForm = styled.form`
    display: flex;
    height: 100%;
    flex-direction: column;
    min-width: 250px;
    width: 60%;
    position: relative;
    border-radius: 0 10px 10px 0;
    justify-content: center;
    align-items: center;
    @media (max-width: 1000px){
        border-radius: 0 0 10px 10px;
        padding: 2vmin 0;
        flex: 1;
    }
    @media (max-width: 550px){
        padding: 4vmin;
    }
`;

const FormRow = styled.div`
    padding: ${ props => props.P ? props.P : 'auto'};
    display: flex;
    justify-content: ${props => props.layout ? props.layout : 'center'};
    margin-top: 4vmin;
    position: relative;
    width: 100%;
    @media (max-width: 550px){
        flex-direction: column;
        margin-top: 0;
        & button{
            margin: 5vmin 0;
        }
    }
`;

const FormInputText = styled.input`
    width: ${props => props.W ? props.W : 'auto'};
    height: ${props => props.H ? props.H : 'auto'};
    padding: 10px 16px;
    border-radius: 7px;
    border: 2px solid #e9e9e9;
    margin: 0 auto;
    font-weight: 500;
    color: #c9c9c9;
    position: relative;

    &::placeholder{
        color: #a7a7a7;  
    }
`;

const FormTextArea = styled.textarea`
    width: ${props => props.W ? props.W : 'auto'};
    height: ${props => props.H ? props.H : 'auto'};
    padding: 10px 16px;
    border-radius: 7px;
    border: 2px solid #e9e9e9;
    margin: 0 auto;
    font-weight: 500;
    color: #c9c9c9;
    position: relative;

    &::placeholder{
        color: #a7a7a7;  
    }
    
    @media (max-width: 550px){
        min-height: 10vmin;
        height: auto;
    }
`;

const InputLabel = styled.label`
    display: flex;
    flex-direction: column;
    align-items: start;
    width: ${props => props.W ? props.W : 'auto'};
    height: ${props => props.H ? props.H : 'auto'};
    margin: 0 auto;
    position: relative;
    @media (max-width: 550px){
        width: 100%;
        height: auto;
        margin: 2vmin auto;
    }
`;

const ContactInfo = styled.div`
    display: flex;
    flex-direction: column;
    background: #387373;
    width: 30vw;
    position: relative;
    border-radius: 10px 0 0 10px;
    padding: 30px;
    padding-top: 10vmin ;
    & div h2{
        margin: 0 0 10px 0;
        font-weight: 900;
        color: #ffffff;
    }
    & div h4{
        margin: 0 0 20px 0;
        color: #ffffff;
    }
    z-index: 1;
    overflow: hidden;
    @media (max-width: 1000px){
        width: 100%;
        border-radius: 10px 10px 0 0;
    }
`;

const ContactSocialContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    margin-top: auto;
`;

const ContactSocialItem = styled.div`
    display: flex;
    position: relative;
    margin: 0 2vmin 0 0;
    max-height: 4vmin;
    max-width: 4vmin;
    min-height: 4vmin;
    min-width: 4vmin;
    & svg{
        height: 4vmin;
        weight: 4vmin;
        color: #CBF2D4;
    }
    &:hover svg{
        color: #2D3E40;
    }
`;
const IconContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
const IconItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;

    & svg{
        color: #CBF2D4;
    }
    & p {
        color: #ffffff;
        margin-left: 1vmin;
    }
`;

const Circle = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    width: ${props => props.alt ? '12vmin' : '20vmin'};
    height: ${props => props.alt ? '12vmin' : '20vmin'};
    border-radius: 50%;
    background-color: ${props => props.alt ? 'rgba(228,242,231,0.45)' : 'rgba(45,62,64,0.60)'};
    transform: ${props => props.alt ? 'translate(-30%,-50%)' : 'translate(20%,20%)'};
`;

const handleSend = (e) => {
        e.preventDefault();
        const Name = document.getElementById('f_name').value +" "+ document.getElementById('l_name').value;
        const Phone = document.getElementById('phone_number').value;
        const Email = document.getElementById('email_address').value;
        const Message = document.getElementById('contact_message').value;
        alert(`Name: ${Name}\nPhone: ${Phone}\nEmail: ${Email}\nMessage: ${Message}\n\nMessage Sent`);
        // Fetch stuff
}

const Contact = () => {
    return (
        <ContactContainer>
            <ContactInfo>
                <div>
                    <h2>Contact Information</h2>
                    <h4>Fill in this form and the support team will get back to you as soon as possible.</h4>
                    <IconContainer>
                        <IconItem>
                            <MailIcon/>
                            <p>support@informedpatient.co.uk</p>
                        </IconItem>
                        <IconItem>
                            <PhoneIcon/>
                            <p>0141234567</p>
                        </IconItem>
                        <IconItem>
                            <PinIcon/>
                            <p>1 River St, G1 1AB, Glasgow</p>
                        </IconItem>
                    </IconContainer>
                </div>
                <ContactSocialContainer>
                    <ContactSocialItem>
                        <TwitterIcon />
                    </ContactSocialItem>
                    <ContactSocialItem>
                        <FacebookIcon />
                    </ContactSocialItem>
                    <ContactSocialItem>
                        <InstagramIcon />
                    </ContactSocialItem>
                </ContactSocialContainer>
                <Circle/>
                <Circle alt/>
            </ContactInfo>
            <ContactFormContainer>
                <ContactForm onSubmit={(e) => handleSend(e)}>
                    <FormRow layout='space-between'>
                        <InputLabel W='40%'>
                            First name
                            <FormInputText type="text" W="100%" id="f_name" placeholder='First name' required/>
                        </InputLabel>
                        <InputLabel W='40%'>
                            Last name
                            <FormInputText type="text" W="100%" id="l_name" placeholder='Last name' required/>
                        </InputLabel>
                    </FormRow>
                    <FormRow layout='space-between'>
                        <InputLabel W='40%'>
                            Phone number
                            <FormInputText type="text" W="100%" id="phone_number" placeholder='07123456789'/>
                        </InputLabel>
                        <InputLabel W='40%'>
                            Email
                            <FormInputText type="email" W="100%" id="email_address" placeholder='name@domain.com' required/>
                        </InputLabel>
                    </FormRow>
                    <FormRow layout='center'>
                        <InputLabel W='90%' H='10vmin'>
                            Message
                            <FormTextArea type="textarea" W="100%" H='10vmin' id="contact_message" placeholder='Your message here...' required/>
                        </InputLabel>
                    </FormRow>
                    
                    <FormRow layout='end'>
                    <Button theme="dark" M="0 5% 0 0">
                        Send
                    </Button>
                    </FormRow>
                </ContactForm>
            </ContactFormContainer>
        </ContactContainer>
    );
}

export default Contact;
