import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { WordLogo, Button } from "../../comp/Styled/StyledComponents";

const AppointmentFormContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
`;

const AppointmentForm = styled.form`
  display: flex;
  max-width: 80%;
  margin: 4vmin auto;
  height: 100%;
  background: #ffffff;
  flex-direction: column;
  flex: 1;
  padding: 4vmin;
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.3);
`;

const FormRow = styled.div`
  padding: ${(props) => (props.P ? props.P : "auto")};
  display: flex;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  justify-content: ${(props) => (props.layout ? props.layout : "center")};
  margin-top: 4vmin;
  position: relative;
  @media (max-width: 450px) {
    flex-direction: column;
    padding: 0;
  }
`;

const FormInputText = styled.input`
  width: ${(props) => (props.W ? props.W : "auto")};
  height: auto;
  padding: 10px 16px;
  border-radius: 7px;
  border: 2px solid #e9e9e9;
  margin: ${(props) => (props.M ? props.M : "0 auto")};
  font-weight: 500;
  color: #999999;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.1);

  &::placeholder {
    color: #a7a7a7;
  }
`;

const FormInputSelect = styled.select`
  width: ${(props) => (props.W ? props.W : "auto")};
  height: auto;
  padding: 10px 16px;
  border-radius: 7px;
  border: 2px solid #e9e9e9;
  margin: 0 auto;
  font-weight: 500;
  color: #999999;
  box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.1);
  & option {
    color: #999999;
  }
  &::placeholder {
    color: #a7a7a7;
  }
`;

const LoginText = styled.p`
  width: 100%;
  text-align: center;
  color: #a1a1a1;
  font-weight: 300;
`;

const Format = styled.span`
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  color: ${(props) => (props.red ? "red" : "inherit")};
  text-transform: ${(props) => (props.uppercase ? "uppercase" : "none")};
`;

const SearchSuggestionContainer = styled.div`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  flex-direction: column;
  height: auto;
  width: ${(props) => (props.W ? props.W : "100%")};
  z-index: 1;
  margin: 0;
  padding: 0;
  position: absolute;
  background: #f1f1f1;
  // border: 1px solid #e9e9e9;
  // border-top: none;
  ${(props) => (props.yOffset ? "transform: translateY(" + props.yOffset + "px);" : "")});
  & p {
    width: 100%;
    height: auto;
    background: #f1f1f1;
    margin: 0;
    padding: 10px 5px;
    &:hover {
      cursor: pointer;
      color: #0f0f0f;
      background: #f5f5f5;
    }
  }
`;

const InputLabel = styled.label`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: ${(props) => (props.W ? props.W : "auto")};
  margin: 0;
  position: relative;
  @media (max-width: 450px) {
    width: 100%;
  }
`;

const RedText = styled.span`
  color: #ff3300;
`;

const Appointmentform = () => {
  const navigate = useNavigate();
  const [locations, setLocations] = useState([{ _id: "6234139a547dfcd881156f67", name: "Test Hospital" }]);
  const [types, setTypeValue] = useState([]);
  const [typeInput, setTypeInput] = useState("");
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const typeInputRef = useRef(null);

  const getLocations = async () => {
    return fetch("http://localhost:5000/hospitals", {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("Atoken")} ${window.localStorage.getItem("Rtoken")}`,
      },
    })
      .then((data) => data.json())
      .then((data) => setLocations(data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getLocations();
    setAppointmentTypes(getAppointmentTypes());
  }, []);

  const handleTypeChange = (e) => {
    if (e.chosen) {
      setTypeValue([]);
      return;
    }
    const val = e.target.value;
    setTypeInput(val);
    let suggestions = [];
    if (val === typeInput) {
      e.preventDefault();
      return null;
    }
    if (appointmentTypes && val !== "" && val !== " ") {
      suggestions = appointmentTypes.filter((type) => type.title.toLowerCase().replace(/\s/g, "").includes(val.toLowerCase().replace(/\s/g, "")));
    }
    for (let type of suggestions) {
      type = type.title.toLowerCase().replace(/\s/g, "");
      if ((val.toLowerCase().replace(/\s/g, "") || typeInput.toLowerCase().replace(/\s/g, "")) === type) {
        setTypeValue([]);
        return;
      }
    }
    setTypeValue(suggestions);
  };

  const getAppointmentTypes = async () => {
    return fetch("http://localhost:5000/appointment/type", {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("Atoken")} ${window.localStorage.getItem("Rtoken")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => setAppointmentTypes(data))
      .catch((err) => console.log(err));
  };

  const handleAppointmentFormSubmit = (e) => {
    console.log(e);
    if (e) {
      console.log(e);
      e.preventDefault();
      const location = document.getElementById("APPOINTMENT_location").value;
      const type = typeInput.code;
      const title = typeInput.title;
      const date = document.getElementById("APPOINTMENT_date").value;
      const time = document.getElementById("APPOINTMENT_time").value;
      const ward = document.getElementById("APPOINTMENT_ward").value;
      console.log("Form Submitted");
      // Fetch stuff
      return fetch("http://86.11.53.197:5000/user/appointment/new", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("Atoken")}`,
        },
        body: JSON.stringify({ reason: "Appointment Form", location: location, title: title, type: type, date: date, time: time, ward: ward }),
      })
        .then((data) => data.json())
        .then((data) => console.log("[Server] ", data))
        .then(navigate("/appointments"))
        .catch((err) => console.log("[Server] ", err));
    }
  };

  return (
    <AppointmentFormContainer>
      <AppointmentForm autoComplete="off" onSubmit={handleAppointmentFormSubmit}>
        <FormRow layout="center" column>
          <WordLogo dark center />
          <LoginText>Appointment Creation Form. Use this form to log your appointment into the Informed Patient System</LoginText>
          <LoginText>
            This form{" "}
            <Format bold uppercase red>
              does not
            </Format>{" "}
            create an appointment with your hospital, this form only allows you to make a record of an existing appointment
          </LoginText>
        </FormRow>
        <FormRow layout="center">
          <InputLabel W="80%">
            <p>
              Appointment Location <RedText>*</RedText>
            </p>
            <FormInputSelect id="APPOINTMENT_location" W="100%">
              <option value="" disabled>
                Select Location
              </option>
              {locations.length > 0 ? (
                locations.map((location) => {
                  return (
                    <option key={location._id} value={location._id}>
                      {location.name}
                    </option>
                  );
                })
              ) : (
                <option value="">No Locations</option>
              )}
            </FormInputSelect>
          </InputLabel>
        </FormRow>
        <FormRow layout="center">
          <InputLabel ref={typeInputRef} W="80%">
            <p>
              Appointment Reason <RedText>*</RedText>
            </p>
            <FormInputText value={typeInput.title} autoComplete="off" type="text" onChange={handleTypeChange} id="APPOINTMENT_type" W="100%" placeholder="Appointment Type" />
            {/* {console.log(typeInputRef)} */}
            <SearchSuggestionContainer W="100%" yOffset={typeInputRef.current?.offsetHeight}>
              {types.map((type) => {
                return (
                  <p
                    onClick={() => {
                      setTypeInput({ title: type.title, code: type.identifier });
                      handleTypeChange({ chosen: true });
                    }}
                    key={type._id}
                  >
                    {type.title}
                  </p>
                );
              })}
            </SearchSuggestionContainer>
          </InputLabel>
        </FormRow>
        <FormRow layout="center">
          <InputLabel W="80%">
            <p>
              Appointment Ward <RedText>*</RedText>
            </p>
            <FormInputText type="text" autoComplete="none" id="APPOINTMENT_ward" W="100%" M="0" placeholder="Appointment Date" />
          </InputLabel>
        </FormRow>
        <FormRow layout="space-between" P="0 10%">
          <InputLabel W="40%">
            <p>
              Appointment Date <RedText>*</RedText>
            </p>
            <FormInputText type="date" autoComplete="none" id="APPOINTMENT_date" W="100%" M="0" placeholder="Appointment Date" />
          </InputLabel>
          <InputLabel W="40%">
            <p>
              Appointment Time <RedText>*</RedText>
            </p>
            <FormInputText type="time" autoComplete="none" id="APPOINTMENT_time" W="100%" M="0" placeholder="Appointment Time" />
          </InputLabel>
        </FormRow>
        <FormRow P="0 10%" layout="end">
          <Button theme="dark">Create Appointment</Button>
        </FormRow>
      </AppointmentForm>
    </AppointmentFormContainer>
  );
};

export default Appointmentform;
