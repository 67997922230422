import { useState } from "react";
import styled from "@emotion/styled";
import loginImage from "../../assets/LoginImage.png";
import loginAltImage from "../../assets/LoginSmallImage.png";
import { WordLogo, Button } from "../../comp/Styled/StyledComponents";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useLoggedContext, useLoggedContextUpdate } from "../../comp/ContextHandler/ContextHandler";

const LoginContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100vw;
  flex: 2;
  position: relative;
  @media (max-width: 1000px) {
    margin: 4vmin 0;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  background: url(${(props) => (props.big ? loginImage : loginAltImage)});
  @media (max-width: 1000px) {
    display: ${(props) => (props.big ? "none" : "inline-block")};
    width: ${(props) => (props.W ? props.W : "100%")};
    height: ${(props) => (props.H ? props.H : "100%")};
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin: 0 auto;
    max-width: 300px;
    max-height: 300px;
  }
`;

const FormContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Form = styled.form`
  width: 60%;
  @media (max-width: 1000px) {
    width: 70%;
  }
  @media (max-width: 550px) {
    width: 80%;
  }
  @media (max-width: 400px) {
    width: 90%;
  }
`;

const FormRow = styled.div`
  padding: ${(props) => (props.P ? props.P : "auto")};
  display: flex;
  justify-content: ${(props) => (props.layout ? props.layout : "center")};
  margin-top: 4vmin;
`;

const FormInputText = styled.input`
  width: ${(props) => (props.W ? props.W : "auto")};
  height: auto;
  padding: 10px 16px;
  border-radius: 7px;
  border: 2px solid #e9e9e9;
  margin: 0 auto;
  font-weight: 500;
  color: #c9c9c9;

  &::placeholder {
    color: #a7a7a7;
  }
`;

const FormInputCheck = styled.input`
  width: ${(props) => (props.W ? props.W : "auto")};
  height: auto;
  border-radius: 7px;
  border: 2px solid #e9e9e9;
  margin-right: 10px;
  font-weight: 500;
  color: #c9c9c9;

  &::placeholder {
    color: #a7a7a7;
  }
`;

const LoginText = styled.p`
  width: 100%;
  text-align: center;
  color: #a1a1a1;
  font-weight: 300;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Login = () => {
  let navigate = useNavigate();
  const updateLogged = useLoggedContextUpdate();

  const handleLoginSubmit = (e) => {
    console.log(e);
    if (e) {
      console.log(e);
      e.preventDefault();
      const email = document.getElementById("email").value;
      const password = document.getElementById("p_word").value;
      const staySignedIn = document.getElementById("stay_in").checked;
      if (email === (null || undefined || "") || password === (null || undefined || "")) throw new Error("Please enter your email and password");
      console.log("Form Submitted");
      // Fetch stuff
      return fetch("http://86.11.53.197:5000/user/login", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email, password: password, staySignedIn: staySignedIn }),
      })
        .then((res) => {
          if (!res.ok) throw new Error("Email or password is incorrect");
          return res.json();
        })
        .then((data) => {
          console.log(data);
          if (data.toString().includes("Error:")) alert(data);
          if (data.Atoken) {
            window.localStorage.setItem("Atoken", data.Atoken);
            window.localStorage.setItem("Rtoken", data.Rtoken);
            updateLogged(true);
          }
          return data;
        })
        .then((data) => {
          if (data.Atoken) navigate("/account");
        })
        .catch((err) => {
          if (err.toString().includes("Error:")) alert(err);
          console.error(err);
        });
    }
  };

  return (
    <LoginContainer>
      <ImageContainer big />
      <FormContainer>
        <Form id="LoginForm" onSubmit={handleLoginSubmit}>
          <FormRow layout="center">
            <ImageContainer W="40vmin" H="40vmin" />
          </FormRow>
          <WordLogo dark center />
          <LoginText>Please log in or sign up for an account</LoginText>
          <FormRow layout="center">
            <FormInputText type="email" required autoComplete="username" id="email" W="80%" placeholder="Email" />
          </FormRow>
          <FormRow layout="center">
            <FormInputText type="password" required autoComplete="current-password" id="p_word" W="80%" placeholder="Password" />
          </FormRow>
          <FormRow P="0 10%" layout="space-between">
            <CheckboxContainer>
              <FormInputCheck type="checkbox" id="stay_in" />
              <label htmlFor="stay_in">Remember me</label>
            </CheckboxContainer>

            <Link to="/">Forgot password</Link>
          </FormRow>
          <FormRow P="0 10%" layout="space-between">
            <Button theme="light">
              <Link to="/signup">Sign Up</Link>
            </Button>
            <Button theme="dark">Login</Button>
          </FormRow>
        </Form>
      </FormContainer>
    </LoginContainer>
  );
};

export default Login;
